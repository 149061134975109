.footer-list {
  &:last-child {
    @apply text-center pl-20 ml-auto sm:pl-0 sm:w-1/4 md:w-auto md:pr-16;
  }

  &:nth-last-child(2) {
    @apply w-1/3 sm:w-auto;
  }

  &:nth-child(2) {
    @apply text-right sm:text-left sm:mr-40 lg:mr-50;
  }
  &:first-child {
    @apply sm:mr-40 lg:mr-50;
  }

}
