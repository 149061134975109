.navigation_wm-icon-smile__G2N5z{
  -o-object-position:1 0;
  object-position:1 0
}

.navigation_wm-icon-menu__hrXD1{
  -o-object-position:0 0;
  object-position:0 0
}

.navigation_wm-icon-close__Q_duw{
  -o-object-position:-75px 0;
  object-position:-75px 0
}
.footer_footer-list__KxnMD:last-child{
  margin-left: auto;
  padding-left: 20px;
  text-align: center
}

@media (min-width: 600px){
  .footer_footer-list__KxnMD:last-child{
    width: 25%;
    padding-left: 0px
  }
}

@media (min-width: 960px){
  .footer_footer-list__KxnMD:last-child{
    width: auto;
    padding-right: 16px
  }
}

.footer_footer-list__KxnMD:nth-last-child(2){
  width: 33.333333%
}

@media (min-width: 600px){
  .footer_footer-list__KxnMD:nth-last-child(2){
    width: auto
  }
}

.footer_footer-list__KxnMD:nth-child(2){
  text-align: right
}

@media (min-width: 600px){
  .footer_footer-list__KxnMD:nth-child(2){
    margin-right: 40px;
    text-align: left
  }
}

@media (min-width: 1280px){
  .footer_footer-list__KxnMD:nth-child(2){
    margin-right: 50px
  }
}

@media (min-width: 600px){
  .footer_footer-list__KxnMD:first-child{
    margin-right: 40px
  }
}

@media (min-width: 1280px){
  .footer_footer-list__KxnMD:first-child{
    margin-right: 50px
  }
}
